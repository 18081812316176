import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { InvoiceReportSummaryResult } from 'src/app/models/Invoice/invoice-report-summary-result';
import { InvoiceService } from 'src/app/services/invoice/invoice.service';
import { saveAs } from 'file-saver';
import { InvoiceReportValueNotesResult } from 'src/app/models/Invoice/invoice-report-value-notes-result';
import { InvoiceReportNoValueNotesResult } from 'src/app/models/Invoice/invoice-report-no-value-notes-result';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  listCountries: any[] = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.CHOOSE.COUNTRY.ARG', value: ConstantsFordProtect.ARG },
    { label: 'TRANSLATE.CHOOSE.COUNTRY.BRA', value: ConstantsFordProtect.BRA },
    { label: 'TRANSLATE.CHOOSE.COUNTRY.CHL', value: ConstantsFordProtect.CHL },
    { label: 'TRANSLATE.CHOOSE.COUNTRY.COL', value: ConstantsFordProtect.COL }
  ];

  listReportType: any[] = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.INVOICE', value: ConstantsFordProtect.INVOICE }
  ];

  listMonths: any[] = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.MONTH.JANUARY.UPPER.CASE', value: ConstantsFordProtect.JANUARY },
    { label: 'TRANSLATE.MONTH.FEBRUARY.UPPER.CASE', value: ConstantsFordProtect.FEBRUARY },
    { label: 'TRANSLATE.MONTH.MARCH.UPPER.CASE', value: ConstantsFordProtect.MARCH },
    { label: 'TRANSLATE.MONTH.APRIL.UPPER.CASE', value: ConstantsFordProtect.APRIL },
    { label: 'TRANSLATE.MONTH.MAY.UPPER.CASE', value: ConstantsFordProtect.MAY },
    { label: 'TRANSLATE.MONTH.JUNE.UPPER.CASE', value: ConstantsFordProtect.JUNE },
    { label: 'TRANSLATE.MONTH.JULY.UPPER.CASE', value: ConstantsFordProtect.JULY },
    { label: 'TRANSLATE.MONTH.AUGUST.UPPER.CASE', value: ConstantsFordProtect.AUGUST },
    { label: 'TRANSLATE.MONTH.SEPTEMBER.UPPER.CASE', value: ConstantsFordProtect.SEPTEMBER },
    { label: 'TRANSLATE.MONTH.OCTOBER.UPPER.CASE', value: ConstantsFordProtect.OCTOBER },
    { label: 'TRANSLATE.MONTH.NOVEMBER.UPPER.CASE', value: ConstantsFordProtect.NOVEMBER },
    { label: 'TRANSLATE.MONTH.DECEMBER.UPPER.CASE', value: ConstantsFordProtect.DECEMBER }
  ];

  listYear: any[] = [
    { label: 'TRANSLATE.SELECT' },
    { label: '2024', value: 2024 },
    { label: '2025', value: 2025 },
    { label: '2026', value: 2026 },
    { label: '2027', value: 2027 }
  ];

  userform: FormGroup;
  selectedCountry: string;
  selectedReportType: string;
  selectedMonth: number;
  selectedYear: number;
  countryBRA: string = ConstantsFordProtect.BRA;
  invoice: string = ConstantsFordProtect.INVOICE;

  // new

  summary: Array<InvoiceReportSummaryResult> = [];
  valueNotes: Array<InvoiceReportValueNotesResult> = [];
  noValueNotes: Array<InvoiceReportNoValueNotesResult> = [];
  blockedUi: boolean = false;
  errorStatus: any = 0;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private invoiceService: InvoiceService,
    private router: Router
  ) { }

  ngOnInit() {

    this.userform = this.fb.group({
      'optionCountry': [null, Validators.required],
      'optionReportType': [null, Validators.required],
      'optionMonth': [null, Validators.required],
      'optionYear': [null, Validators.required]
    });

    (<HTMLInputElement>document.getElementById("countryNotBra")).style.display = "none";
  }

  countryChange(event: any) {

    this.selectedCountry = event.value;
    this.checkCountry(this.selectedCountry);
  }

  checkCountry(country: string) {

    if (country === ConstantsFordProtect.BRA) {

      (<HTMLInputElement>document.getElementById("countryNotBra")).style.display = "none";
    } else {

      (<HTMLInputElement>document.getElementById("countryNotBra")).style.display = "block";
    }

  }

  reportTypeChange(event: any) {

    this.selectedReportType = event.value;
  }

  monthChange(event: any) {

    this.selectedMonth = event.value;
  }

  yearChange(event: any) {

    this.selectedYear = event.value;
  }

  buttonClean() {

    (<HTMLInputElement>document.getElementById("countryNotBra")).style.display = "none";

    this.selectedCountry = undefined;
    this.selectedReportType = undefined;
    this.selectedMonth = undefined;
    this.selectedYear = undefined;
    this.userform.reset();
  }

  async exportExcel() {

    if (this.selectedCountry !== ConstantsFordProtect.BRA && this.selectedCountry !== undefined && this.selectedCountry !== null) {

      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.CURRENTLY.NO.REPORTS.COUNTRY')
      });

    } else if (this.userform.invalid) {

      if (this.selectedCountry === undefined || this.selectedCountry === null) {
        this.userform.get('optionCountry').markAsTouched();
        this.selectedCountry = null;
      }
      else if (this.selectedReportType === undefined || this.selectedReportType === null) {
        this.userform.get('optionReportType').markAsTouched();
        this.selectedReportType = null;
      }
      else if (this.selectedMonth === undefined || this.selectedMonth === null) {

        this.userform.get('optionMonth').markAsTouched();
        this.selectedMonth = null;
      }
      else if (this.selectedYear === undefined || this.selectedYear === null) {
        this.userform.get('optionYear').markAsTouched();
        this.selectedYear = null;
      }

    } else if (this.userform.valid) {

      this.blockedUi = true;
  
      // aba 1: resumo
      await this.invoiceService.invoiceReportSummaryPromise(this.selectedYear.toString(), this.selectedMonth.toString())
        .then((response: Array<InvoiceReportSummaryResult>) => {
  
          this.summary = response;
          this.blockedUi = false;
  
        }, (err) => {
          this.errorStatus = err.status;
        });  
  
      // aba 2: notas com valor
      await this.invoiceService.invoiceReportValueNotesPromise(this.selectedYear.toString(), this.selectedMonth.toString())
        .then((response: Array<InvoiceReportValueNotesResult>) => {
  
          this.valueNotes = response;
          this.blockedUi = false;
  
        }, (err) => {
          this.errorStatus = err.status;
        });
  
      // aba 3: notas sem valor
      await this.invoiceService.invoiceReportNoValueNotesPromise(this.selectedYear.toString(), this.selectedMonth.toString())
        .then((response: Array<InvoiceReportNoValueNotesResult>) => {
  
          this.noValueNotes = response;
          this.blockedUi = false;
  
        }, (err) => {
          this.errorStatus = err.status;
        });
  
      if (this.errorStatus > 0) {

        if (this.errorStatus === 400 || this.errorStatus === 404 || this.errorStatus === 500 ) {
          
          this.messageService.add({
            severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.EXCEL.CANNOT.BE.EXPORTED')
          });

        } else if(this.errorStatus === 401) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
           });
        
        } else {
        
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
          });
        }
        
      } else {
        this.importXlsxMultiTabFromArray(this.summary, this.valueNotes, this.noValueNotes);

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('TRANSLATE.SUCCESS'),
          detail: this.translateService.instant('TRANSLATE.REPORT.GENERATED.SUCCESSFULLY')
        });
        this.router.navigate(['home'], { skipLocationChange: false, replaceUrl: false });
      }

      this.blockedUi = false;

    } else {

      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.ERROR.PLEASE.REQUIRED.FIELDS')
      });
    }
  }


  private importXlsxMultiTabFromArray(summary: Array<InvoiceReportSummaryResult>
    , valueNotes: Array<InvoiceReportValueNotesResult>
    , noValueNotes: Array<InvoiceReportNoValueNotesResult>): void {
    import("xlsx").then(xlsx => {
      let worksheetSummary = xlsx.utils.json_to_sheet(summary);

      // adicionar totalizadores      
      xlsx.utils.sheet_set_array_formula(worksheetSummary, "C10", "(\"TOTAL \")");
      xlsx.utils.sheet_set_array_formula(worksheetSummary, "D10", "SUM(D2:D8)");
      xlsx.utils.sheet_set_array_formula(worksheetSummary, "E10", "SUM(E2:E8)");
      xlsx.utils.sheet_set_array_formula(worksheetSummary, "F10", "SUM(F2:F8)");

      const worksheetValueNotes = xlsx.utils.json_to_sheet(valueNotes);
      const worksheetNoValueNotes = xlsx.utils.json_to_sheet(noValueNotes);
      const workbook = { Sheets: { 'Resumo': worksheetSummary, 'Notas com valor': worksheetValueNotes, 'Notas sem valores': worksheetNoValueNotes }, SheetNames: ['Resumo', 'Notas com valor', 'Notas sem valores'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "invoices");
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + EXCEL_EXTENSION);
  }

}