import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { ViewPlans } from 'src/app/models/enum/view-plans';
import { NewPlansApplication } from 'src/app/models/interface/common/new-plans.application';
import { CoverageCategoryOws } from 'src/app/models/product/coverage-category-ows';
import { PriceDto } from 'src/app/models/product/price-dto';
import { PriceSearchCriteria } from 'src/app/models/product/price-search-criteria';
import { ProductPolicy } from 'src/app/models/product/product-policy';
import { ProductVisit } from 'src/app/models/product/product-visit';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { CoverageService } from 'src/app/services/coverage/coverage.service';
import { PriceService } from 'src/app/services/price/price.service';
import { ProductPolicyService } from 'src/app/services/product-policy/product-policy.service';
import { ProductService } from 'src/app/services/product/product.service';
import { CountryName } from 'src/assets/functions/get_country_name';

@Component({
  selector: 'view-plans',
  templateUrl: './view-plans.component.html',
  styleUrls: ['./view-plans.component.scss']
})
export class ViewPlansComponent implements OnInit {

  statusPlan = [
    { label: 'TRANSLATE.PRODUCT', value: ViewPlans.PRODUCT },
    { label: 'TRANSLATE.COVERAGE', value: ViewPlans.COVERAGE },
    { label: 'TRANSLATE.PRICE', value: ViewPlans.PRICE },
    { label: 'TRANSLATE.ELIGIBILITY', value: ViewPlans.ELIGIBILITY }
  ];

  
  countries() {

    return [
      { label: this.translateService.instant('TRANSLATE.CHOOSE.COUNTRY.ARG'), value: ConstantsFordProtect.ARG },
      { label: this.translateService.instant('TRANSLATE.CHOOSE.COUNTRY.BRA'), value: ConstantsFordProtect.BRA },
      { label: this.translateService.instant('TRANSLATE.CHOOSE.COUNTRY.CHL'), value: ConstantsFordProtect.CHL },
      { label: this.translateService.instant('TRANSLATE.CHOOSE.COUNTRY.COL'), value: ConstantsFordProtect.COL }
    ];
  }

  currencyList() {
    return [
      { label: this.translateService.instant('TRANSLATE.SELECT.COUNTRY.CURRENCY.BRA'), value: ConstantsFordProtect.REAL },
      { label: this.translateService.instant('TRANSLATE.SELECT.COUNTRY.CURRENCY.OTHERS'), value: ConstantsFordProtect.PESO }
    ];
  }

  priceTypeList() {

    return [
      { label: this.translateService.instant('TRANSLATE.CUSTOMER.PRICE'), value: ConstantsFordProtect.PRICE_CUSTOMER },
      { label: this.translateService.instant('TRANSLATE.FORD.COST'), value: ConstantsFordProtect.COST_FORD },
      { label: this.translateService.instant('TRANSLATE.DEALER.PRICE'), value: ConstantsFordProtect.SELLER_PRICE }
    ];
  }

  selectTypePlan: string = this.statusPlan[0].value;
  bottonItems: NewPlansApplication[];
  selectedNewPlans: NewPlansApplication;
  product: any;
  coverage: CoverageCategoryOws;
  products: Array<any> = [];
  selectedProducts: Array<any> = [];
  selectedCountries: Array<string> = [];
  selectedProductsResult: Array<any> = [];
  countriesGet: Array<string> = [];
  priceSearchCriteria: PriceSearchCriteria = { currencies: [], priceTypes: [] };
  selectedCurrencies: Array<string> = [];
  selectedPriceTypes: Array<string> = [];
  blockedUi: boolean = false;
  loadingPlans: boolean = false;

  constructor(
    private apsPermissionService: ApsPermissionService,
    public translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private productService: ProductService,
    private coverageService: CoverageService,
    private productPolicyService: ProductPolicyService,
    private priceService: PriceService,
    private countryName: CountryName
  ) { }

  ngOnInit() {

    this.getMenu();
  }

  async getMenu() {

    // renderiza os itens de menu, na primeira vez que acessar a tela
    this.navMenuItemsArray();

    // renderiza ao fazer a troca de linguagem
    this.translateService.onLangChange.subscribe(
      async (translate: LangChangeEvent) => (
        this.navMenuItemsArray()
      ));
  }

  async navMenuItemsArray() {

    this.bottonItems = [
      {
        label: this.translateService.instant('TRANSLATE.ADD'),
        icon: 'fa fa-plus',
      },
      {
        label: this.translateService.instant('TRANSLATE.PRODUCT'),
        routerLink: '/new-product'
      },
      {
        label: this.translateService.instant('TRANSLATE.COVERAGE'),
        routerLink: '/new-coverage'
      },
      {
        label: this.translateService.instant('TRANSLATE.PRICE'),
        routerLink: '/new-price'
      },
      {
        label: this.translateService.instant('TRANSLATE.ELIGIBILITY'),
        routerLink: '/new-eligibility'
      }
    ];
  }

  changeLang(plan: string) {

    if (plan === this.translateService.instant('TRANSLATE.PRODUCT')) {
      this.router.navigate(['new-product'], { skipLocationChange: false, replaceUrl: false });

    } else if (plan === this.translateService.instant('TRANSLATE.COVERAGE')) {
      this.router.navigate(['new-coverage'], { skipLocationChange: false, replaceUrl: false });

    } else if (plan === this.translateService.instant('TRANSLATE.PRICE')) {
      this.router.navigate(['new-price'], { skipLocationChange: false, replaceUrl: false });

    } else if (plan === this.translateService.instant('TRANSLATE.ELIGIBILITY')) {
      this.router.navigate(['new-eligibility'], { skipLocationChange: false, replaceUrl: false });

    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.URL.NOT.FOUND')
      });
    }

  }

  generalSearch() {

    this.cleanFilters();
  }
  
  countryChange(event: any) {
    this.cleanFilters();
    
    this.selectedCountries = event.value;
    this.clearTable();
    this.getAllPlansByCountry();
  }

  productChange(event: any) {
    this.selectedProducts = event.value;
  }

  currencyChange(event: any) {

    this.selectedCurrencies = event.value;
    this.updateSearchCriteria();
    this.clearTable();
    this.searchForAllPricesAndCanHaveCurrencyAndPriceTypeCriteria();
  }

  priceTypeChange(event: any) {

    this.selectedPriceTypes = event.value;
    this.updateSearchCriteria();
    this.clearTable();
    this.searchForAllPricesAndCanHaveCurrencyAndPriceTypeCriteria();
  }

  updateSearchCriteria() {

    if (this.selectedCurrencies === undefined) {

      this.priceSearchCriteria.currencies = [];
    } else {

      this.priceSearchCriteria.currencies = this.selectedCurrencies;
    }
    if (this.selectedPriceTypes === undefined) {

      this.priceSearchCriteria.priceTypes = [];
    } else {

      this.priceSearchCriteria.priceTypes = this.selectedPriceTypes;
    }

    return this.priceSearchCriteria;
  }

  searchForAllPricesAndCanHaveCurrencyAndPriceTypeCriteria() {

    if (this.selectTypePlan === ViewPlans.PRICE) {
      
      if (this.selectedProducts.length > 0) {

        this.selectedProductsResult = this.selectedProducts;
        this.blockedUi = false;
      } else {

        this.priceService.searchPrice(this.updateSearchCriteria())
          .subscribe((response: Array<PriceDto>) => {

            this.products = response;
            this.blockedUi = false;
            if (this.products.length == 0) {

              this.blockedUi = false;
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('TRANSLATE.ERROR'),
                detail: this.translateService.instant('TRANSLATE.NO.RESULT.FOUND')
              });
            }

          }, (err) => {

            this.blockedUi = false;
            if (err.status == 400) {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('TRANSLATE.ERROR'),
                detail: this.translateService.instant('TRANSLATE.PRICE.NOT.FOUND')
              });
            } else if (err.status === 401) {

              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('TRANSLATE.ERROR'),
                detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
              });

            } else {

              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('TRANSLATE.ERROR'),
                detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
              });
            }

          });

      }

    }

  }

  getAllPlansByCountry() {

    switch (this.selectTypePlan) {
      
      case ViewPlans.PRODUCT:
        
        this.loadingPlans = true;
        if (this.selectedProducts.length > 0) {

          this.selectedProductsResult = this.selectedProducts;
          this.blockedUi = false;
          this.loadingPlans = false;

        } else {

          this.productService.getProductsByCountry(this.selectedCountries)
            .subscribe((response: Array<ProductVisit>) => {

              this.products = response.map(objProduct => {
                objProduct.country = this.countryName.getCountryName(objProduct.country);
                return objProduct;
              });
              this.blockedUi = false;
              this.loadingPlans = false;

              if (this.products.length == 0) {

                this.blockedUi = false;
                this.loadingPlans = false;
                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.NO.RESULT.FOUND')
                });
              }

            }, (err) => {

              this.blockedUi = false;
              this.loadingPlans = false;
              if (err.status == 400) {

                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
                });
              } else if (err.status === 401) {

                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
                });

              } else {

                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
                });
              }

            });
        }
        break;

      case ViewPlans.COVERAGE:

        this.loadingPlans = true;
        if (this.selectedProducts.length > 0) {

          this.selectedProductsResult = this.selectedProducts;
          this.blockedUi = false;
          this.loadingPlans = false;

        } else {

          this.coverageService.getCoveragesByCountry(this.selectedCountries)
            .subscribe((response: Array<CoverageCategoryOws>) => {

              this.products = response.map(objCoverage => {
                objCoverage.country = this.countryName.getCountryName(objCoverage.country);
                return objCoverage;
              });
              this.blockedUi = false;
              this.loadingPlans = false;

              if (this.products.length == 0) {

                this.blockedUi = false;
                this.loadingPlans = false;
                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.NO.RESULT.FOUND')
                });
              }

            }, (err) => {

              this.blockedUi = false;
              this.loadingPlans = false;
              if (err.status == 400) {

                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.COVERAGE.NOT.FOUND')
                });
              } else if (err.status === 401) {

                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
                });

              } else {

                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
                });
              }

            });
        }
        break;

      case ViewPlans.ELIGIBILITY:
        
        this.loadingPlans = true;
        if (this.selectedProducts.length > 0) {

          this.selectedProductsResult = this.selectedProducts;
          this.blockedUi = false;
          this.loadingPlans = false;

        } else {

          this.productPolicyService.getAllProductPoliciesByCountry(this.selectedCountries)
            .subscribe((response: Array<ProductPolicy>) => {

              this.products = response.map(objEligibility => {
                objEligibility.country = this.countryName.getCountryName(objEligibility.country);
                return objEligibility;
              });
              this.blockedUi = false;
              this.loadingPlans = false;
              
              if (this.products.length == 0) {
                
                this.blockedUi = false;
                this.loadingPlans = false;
                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.NO.RESULT.FOUND')
                });
              }

            }, (err) => {

              this.blockedUi = false;
              this.loadingPlans = false;
              if (err.status == 400) {
                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.ELEGIBILITY.NOT.FOUND')
                });
              } else if (err.status === 401) {

                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
                });

              } else {

                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('TRANSLATE.ERROR'),
                  detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
                });
              }

            });

        }
        break;
    }

  }

  

  validateSearch() {

    if (this.selectTypePlan === ViewPlans.PRICE) {
  
      if (this.products.length === 0) {
  
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.NO.PRICES.AVAILABLE.FOR.THIS.SEARCH')
        });
  
      } else {
        
        this.blockedUi = true;
        this.searchForAllPricesAndCanHaveCurrencyAndPriceTypeCriteria();
        this.checkSelectedProducts();
      }
  
    } else {
  
      if (this.selectedCountries.length > 0) {
        
        this.blockedUi = true;
        this.getAllPlansByCountry();
        this.checkSelectedProducts();
  
      } else {
  
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.SELECT.COUNTRIES')
        });
      }
  
    }

  }

  clearTable() {

    this.selectedProductsResult = [];
    this.selectedProducts = [];
    this.product = "";
  }

  cleanFilters() {

    this.products = [];
    this.selectedCountries = [];
    this.selectedProductsResult = [];
    this.selectedProducts = [];
    this.selectedCurrencies = [];
    this.selectedPriceTypes = [];
    this.product = "";
    this.searchForAllPricesAndCanHaveCurrencyAndPriceTypeCriteria();
  }

  checkSelectedProducts() {

    if (this.selectedProducts.length > 0) {

      this.selectedProductsResult = this.selectedProducts;
    } else {

      this.selectedProductsResult = this.products;
    }
  }

}