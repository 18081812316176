import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { LanguageApplication } from 'src/app/models/interface/common/language.application';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

	allPermissions: String[] = [];
	isAccessManagement: boolean = false;
	languageActive = false;
	languages: LanguageApplication[];
	selectedLanguage: LanguageApplication;
	systemCountries: LanguageApplication[];
	selectedSystemCountry: LanguageApplication;
	snapshotUrl: String;
	
	constructor(
		private apsPermissionService: ApsPermissionService,
		public translate: TranslateService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {
		const browserLang = translate.getBrowserLang();

		this.translate.addLangs([
			ConstantsFordProtect.ES_AR,  
			ConstantsFordProtect.PT_BR, 
			ConstantsFordProtect.ES_CL, 
			ConstantsFordProtect.ES_CO
		]);
		
		this.translate.setDefaultLang(ConstantsFordProtect.PT_BR);
		this.translate.use(ConstantsFordProtect.PT_BR);
		this.changeLang(ConstantsFordProtect.PT_BR);

		this.languages = [
			
			{ label: 'TRANSLATE.SELECT', value: ConstantsFordProtect.PT_BR, flag: null},
			{ label: 'TRANSLATE.CHOOSE.COUNTRY.LANGUAGE.ARG', value: ConstantsFordProtect.ES_AR, flag: ConstantsFordProtect.ES_AR },
			{ label: 'TRANSLATE.CHOOSE.COUNTRY.LANGUAGE.BRA', value: ConstantsFordProtect.PT_BR, flag: ConstantsFordProtect.PT_BR },
			{ label: 'TRANSLATE.CHOOSE.COUNTRY.LANGUAGE.CHL', value: ConstantsFordProtect.ES_CL, flag: ConstantsFordProtect.ES_CL },
			{ label: 'TRANSLATE.CHOOSE.COUNTRY.LANGUAGE.COL', value: ConstantsFordProtect.ES_CO, flag: ConstantsFordProtect.ES_CO },
		];
				
		this.systemCountries = [
			
			{ label: 'TRANSLATE.SELECT', value: ConstantsFordProtect.BRA, flag: null},
			{ label: 'TRANSLATE.CHOOSE.COUNTRY.ARG', value: ConstantsFordProtect.ARG , flag: ConstantsFordProtect.ES_AR },
			{ label: 'TRANSLATE.CHOOSE.COUNTRY.BRA', value: ConstantsFordProtect.BRA, flag: ConstantsFordProtect.PT_BR },
			{ label: 'TRANSLATE.CHOOSE.COUNTRY.CHL', value: ConstantsFordProtect.CHL, flag: ConstantsFordProtect.ES_CL },
			{ label: 'TRANSLATE.CHOOSE.COUNTRY.COL', value: ConstantsFordProtect.COL, flag: ConstantsFordProtect.ES_CO },
		];

	}

	ngOnInit(): void {
		setTimeout(() => {
			// recuperar a url
			this.snapshotUrl = this.activatedRoute.snapshot['_routerState'].url;

			// verifica se a url é aberta. Se sim, omite os itens de menu
			if (!this.snapshotUrl.includes(ConstantsFordProtect.OPEN_URL_FRONT)) {
				this.apsPermissionService.getSearchApsPermission();
				this.getPermissions();
			}
			sessionStorage.setItem(ConstantsFordProtect.SYSTEM_COUNTRY, this.apsPermissionService.getUserCountry());
		}, 1000);
	}

	changeLang(lang: string) {
		this.translate.use(lang);
		this.languageActive = true;
	}

	changeSystemCountry(systemCountrySelected: string) {
		sessionStorage.removeItem(ConstantsFordProtect.SYSTEM_COUNTRY);
		sessionStorage.setItem(ConstantsFordProtect.SYSTEM_COUNTRY, systemCountrySelected)
		this.router.navigate(['home'], { skipLocationChange: false, replaceUrl: false });
	}

	async getPermissions() {
		/*
		lê todas as permissões do usuário logado 
		e armazena na variável allPermissions
		*/
		await this.getListAllPermissions().then((success: String[]) => {
			this.allPermissions = success;
		});

		await this.loadScreenPermissions();
	}

	async getListAllPermissions(): Promise<String[]> {
		let allPermissions: String[] = [];
		await this.apsPermissionService.getListPermissions().then((list: String[]) => {
			allPermissions = list;
		});
		return allPermissions;
	}

	async loadScreenPermissions() {
		await this.checkPermission(ApsPermitedScreen.accessManagement).then((success: boolean) => {
			if (success) {
				this.isAccessManagement = true;
			}
		});
	}

	async checkPermission(permissionName: string): Promise<boolean> {

		let checkPermission = false;
		this.allPermissions.forEach(pm => {
			if (pm === permissionName) {
				checkPermission = true;
			}
		});

		return checkPermission;
	}
}